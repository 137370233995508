<div class="row aft-grid-toolbar">
  <!-- Switch -->

  <!-- Search box -->
  <!-- <div class="col-2 col-xs-2 col-md-2">
  </div> -->

  <!-- Toolbar title -->
  <!-- <div class="col-8 col-xs-4 col-md-6">
    <div class="aft-grid-toolbar__title-wrapper">
      <span class="aft-grid-toolbar__title aft-whitespace-prewrap">{{options.title}}</span>
    </div>
  </div> -->

  <!-- Buttons -->
  <!-- <div class="col-2 col-xs-6 col-md-4 aft-text-align-right">
    <a class="btn btn-default btn-xs btn-square-xs aft-margin-left-10 positionTag" (click)="btnPdfClick()" *ngIf="options.showPDF"
    pTooltip="{{'global.downloadletters' | translate }}" tooltipPosition="left">
    <span class="fa fa-file-pdf"></span>
    </a>
    <a class="btn btn-default btn-xs btn-square-xs mx-8" (click)="btnExportClick()"
      *ngIf="options.showExport"
      pTooltip="{{'global.export' | translate }}" tooltipPosition="left">
      <span class="fa fa-file-excel"></span>

    </a>
  </div> -->

  <div class="flex flex-row-reverse ">
    <div class="p-1">
      <div class="aft-grid-toolbar__title-wrapper">
        <span class="aft-grid-toolbar__title aft-whitespace-prewrap">{{options.title}}</span>
      </div>
    </div>
    <div class="p-1 mr-2">
      <a class="btn btn-default btn-xs btn-square-xs " (click)="btnDetailTable()" *ngIf="options.showDetailTable"
        pTooltip="{{'global.detailDriversWithoutViolations' | translate }}" tooltipPosition="left">
        <span class="fa-solid fa-table"></span>
      </a>
    </div>
    <div class="p-1 mr-2">
      <a class="btn btn-default btn-xs btn-square-xs " (click)="btnPdfClick()" *ngIf="options.showPDF"
        pTooltip="{{'global.downloadletters' | translate }}" tooltipPosition="left">
        <span class="fa fa-file-pdf"></span>
      </a>
    </div>
    <div class="p-1 mr-2">
      <a class="btn btn-default btn-xs btn-square-xs" (click)="btnExportClick()" *ngIf="options.showExport"
        pTooltip="{{'global.export' | translate }}" tooltipPosition="left">
        <span class="fa fa-file-excel"></span>

      </a>
    </div>

  </div>