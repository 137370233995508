import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { WarningModalComponent } from 'src/app/shared/components/warning-modal/warning-modal.component';
import { EMAIL_PATTERN, LOGINAS_OF_CUSTOMER, LOGINAS_USER } from '../config/config.constants';
import { DataService } from './data.service';
const datepipe: DatePipe = new DatePipe('en');
import * as moment from 'moment-timezone';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { IReportEmbedConfiguration, models, service, Page, Report, Embed } from 'powerbi-client';
import * as XLSX from 'xlsx';
const MILES_CONV = 0.621371192;
const GALLONS_CONV = 0.21996925;
const GALLONS_US_CONV = 0.26417205;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private dataTable = new BehaviorSubject<any>({});
  currentData = this.dataTable.asObservable();

  diag410;

  public refreshHistory = new Subject();

  constructor(
    private dialog: MatDialog,
    private dataService: DataService,
    private apiService: ApiService
  ) { }

  hostName = "";
  error: any;
  realm: string = "";

  setHostName(hostname: string) {
    this.hostName = hostname;
  }

  getHostName() {
    return this.hostName;
  }

  getNowTimezoneJS() {
    return this.toUserTimezoneDateJS(moment().valueOf());
  }

  getStylesheetString() {
    // uncomment this line to test a whitelabel
    //return 'dierre';

    switch (location.host) {
      case 'web.dms.srl': return 'dms';
      case 'sctacho.recorditalia.net': return 'record';
      case 'actiafleet.satelicom.com': return 'satelicom';
      case 'fleet.wortach.com': return 'wortach';
      case 'chrono.fleetcontrol.it': return 'chrono';
      case 'chronofleet.siaksolution.it': return 'siak';
      case 'fleetmgmt.intellic.com': return 'intellic';
      case 'flotte.ddrspa.com': return 'dierre';
      default: return 'aft';
    }
  }

  getStylesheetStringHeaderComponent() {
    switch (location.host) {
      case 'web.dms.srl': return 'dms';
      case 'sctacho.recorditalia.net': return '';
      case 'actiafleet.satelicom.com': return 'satelicom';
      case 'fleet.wortach.com': return 'wortach';
      case 'chrono.fleetcontrol.it': return 'chrono';
      case 'chronofleet.siaksolution.it': return 'siak';
      case 'fleetmgmt.intellic.com': return 'intellic';
      case 'flotte.ddrspa.com': return 'dierre';
      default: return 'aft';
    }
  }


  validateEmail(email: string) {
    return (email && email !== null && email.match(EMAIL_PATTERN) !== null);
  }

  getTodayTimezoneJS() {
    return this.toUserTimezoneDateJS(moment().startOf('day').valueOf());
  }

  convertLanguageCode(): string {
    let user = this.dataService.getCurrentUser().user
    let language = ''
    switch (user.preferences.language.label) {
      case 'it':
        language = 'it-IT'
        break;
      case 'en':
        language = 'en-US'
        break;
      case 'fr':
        language = 'fr-FR'
        break;
      case 'es':
        language = 'es-ES'
        break;
      case 'de':
        language = 'de-DE'
        break;
      default:
        language = 'it-IT'
        break;
    }

    return language;
  }


  getMenuItem(menu, labelToFound) {
    var menuItem;
    _.each(menu, function (item: any) {
      if (item.label === labelToFound) {
        menuItem = item;
      } else if (item.child.length > 0) {
        //checking childrens
        var childItemFouneded = _.find(item.child, { label: labelToFound });
        if (childItemFouneded !== undefined) {
          menuItem = childItemFouneded;
        }
      }
    });
    return menuItem;
  }

  getMainColor() {
    switch (this.getStylesheetString()) {
      case 'dms': return 'rgb(47, 65, 144)';
      case 'chrono': return 'rgb(231, 115, 7)';
      case 'siak': return '#0056a8';
      case 'intellic': return 'rgb(213, 19, 23)';
      case 'record': return 'rgb(10, 59, 125)';
      case 'dierre': return '#005d98';
      default:
        return '#008c95b6';
    }
  }

  chartCrossHair(customizeText?) {
    return {
      enabled: true,
      color: this.getMainColor(),
      width: 3,
      dashStyle: "dot",
      label: {
        visible: true,
        backgroundColor: this.getMainColor(),
        font: {
          color: "#fff",
          size: 12,
        },
        customizeText: customizeText
      }
    };
  }

  toUTC(dateJS) {
    //subtract utc offset to timestamp
    var utcDate = moment.utc(dateJS).subtract(dateJS.getTimezoneOffset(), 'm').subtract(moment(dateJS).utcOffset(), 'm').valueOf();
    return utcDate;
  }

  fromUTC_toDateJS(timestamp) {
    var offset = new Date(timestamp).getTimezoneOffset();
    var userDate = moment(timestamp).add(offset, 'm').toDate();
    return userDate;
  }

  arraysplit(arr: any, size) {
    let arrays: any[] = [];
    while (arr.length > 0) {
      arrays.push(arr.splice(0, size));
    }
    return arrays;
  }

  showErrorModal(error: any) {
    let errorMessage = 'global.error.server.internalerror'
    if (error?.error?.errorCode) {
      errorMessage = 'global.error.functionalCodes.' + error?.error?.errorCode;
    } else if (error?.error?.length > 0 && error?.error[0]?.errorCode) {
      errorMessage = 'global.error.functionalCodes.' + error?.error[0]?.errorCode;
    }
    return errorMessage
  }

  showErrorPopup(error: any) {
    let self = this;
    if (error?.status === 410) {
      if (this.diag410) {
        return;
      }
      this.diag410 = this.dialog.open(ConfirmationModalComponent, {
        width: '500px',
        data: "keycloak.410Confirm",
        position: { top: '40px' }
      });
      this.diag410.afterClosed().subscribe(result => {
        if (result) {
          sessionStorage.removeItem('tokenLoginAs');
          self.apiService.post(LOGINAS_USER, {
            uidUser: sessionStorage.getItem('userLoginAs')
          }).subscribe(
            (tok: any) => sessionStorage.setItem('tokenLoginAs', tok.token)
          );
        } else {
          sessionStorage.removeItem('tokenLoginAs');
          window.location.assign("/");
        }
        this.diag410 = undefined;
      });
      return;
    }

    let errorMessage = 'global.error.server.internalerror'
    if (error?.error?.errorCode) {
      errorMessage = 'global.error.functionalCodes.' + error?.error?.errorCode;
    } else if (error?.error?.length > 0 && error?.error[0]?.errorCode) {
      errorMessage = 'global.error.functionalCodes.' + error?.error[0]?.errorCode;
    }
    this.dialog.open(WarningModalComponent, {
      width: '500px',
      data: errorMessage,
      position: { top: '40px' }
    });

  }

  showWarningDialog(errorMessage) {
    this.dialog.open(WarningModalComponent, {
      width: '500px',
      data: errorMessage,
      position: { top: '40px' }
    });

  }

  getUids(input, objRecordsBool, uidRecordName, paramToValidate?, paramBooleanValue?) {
    var toRet: any = [];

    if (input && typeof input[0] === "object") {

      if (objRecordsBool) {
        _.each(input, function (item: any) {
          if (!(paramToValidate) || hasPropertyAndMatch(item)) {
            var obj = {};
            obj[uidRecordName] = item.uid;
            toRet.push(obj);
          }
        });
      } else {
        _.each(input, function (item: any) {
          if (!(paramToValidate) || hasPropertyAndMatch(item)) {
            toRet.push(item.uid);
          }
        });
        toRet = toRet.join(',');
      }

    } else if (input && typeof input[0] === "string") {

      _.each(input, function (item) {
        var obj = {};
        obj[uidRecordName] = item;
        toRet.push(obj);
      });
    }
    return toRet;

    function hasPropertyAndMatch(item) {
      return item.hasOwnProperty(paramToValidate) && item[paramToValidate] === paramBooleanValue;
    }
  }

  sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  guid() {
    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
      this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }

  //Format epochTime(milliseconds) in formatType('short is default') from UTC to UserTimeZone
  //na=true return N/A if value is null
  formatterDateToUserTimezone(value: any, formatType: any, na: boolean) {

    if (na === true && !_.isNumber(value))
      return "N/A";
    else {
      var temp = this.toUserTimezone(value);
      return this.formatterDateFromEpoch(temp, formatType);
    }
  }

  //Convert datetime to user Timezone
  toUserTimezone(timestamp: any) {
    //add utc offset to timestamp
    var userDate = moment(timestamp).add(moment(timestamp).utcOffset(), 'm').valueOf();
    return userDate;
  }

  //Format epochTime(milliseconds) in formatType('short is default')
  formatterDateFromEpoch(value: any, formatType?: any) {
    let user = this.dataService.getCurrentUser().user;
    let userUtc = user.preferences.timezone.description;
    if (_.isEmpty(formatType))
      formatType = 'short';
    if (formatType === 'complete')
      return datepipe.transform(value, 'shortDate', userUtc) + ' ' + datepipe.transform(value, 'mediumTime', userUtc);
    return datepipe.transform(value, formatType, userUtc);
  }

  userTimezoneDate(timestamp) {
    let finalDate = new Date(timestamp);
    // get the timezone offset in minutes
    const offsetMinutes = finalDate.getTimezoneOffset();
    // check if it's currently summer time
    const isSummerTime = offsetMinutes < -60;
    if (isSummerTime) {
      finalDate.setHours(finalDate.getHours() + 1);
    }

    let user = this.dataService.getCurrentUserInfo();
    var userTimestamp = moment(finalDate).add(moment.tz(finalDate, user.user.preferences.timezone.label).utcOffset(), 'm').valueOf()
    var browserOffset = moment.tz(finalDate, moment.tz.guess(true)).utcOffset();
    var userDate = moment(userTimestamp).subtract(browserOffset, 'm').toDate();
    return userDate;
  }

  getDrivernameWithCardId(driver) {
    if (_.isEmpty(driver.fullName))
      driver.fullName = driver.lastName + ' ' + driver.firstName;
    if (!_.isEmpty(driver.cardId))
      return driver.fullName + ' (' + driver.cardId + ')';
    else
      return driver.fullName;
  }

  /*
  * 0 smile green
  * 1 smile orange
  * 2 smile red
  * 3 X
  */
  getSmile(level: number) {
    switch (level) {
      case 0:
        return '<i class="fa fa-smile fa-lg text-success"></i>';
      case 1:
        return '<i class="fa fa-meh fa-lg text-warning"></i>';
      case 2:
        return '<i class="fa fa-frown fa-lg text-danger"></i>';
      case 3:
        return '<i class="fa fa-times fa-lg text-danger"></i>';
    }
    return "";
  }

  searchHighlight(html: any, term: any) {
    var src_str = html.html();
    term = term.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
    var pattern = new RegExp("(" + term + ")", "gi");

    src_str = src_str.replace(/<mark class="dx-datagrid-search-text">/gi, "");
    src_str = src_str.replace(/<\/mark>/gi, "");
    if (!_.isEmpty(term)) {
      src_str = src_str.replace(pattern, '<mark class="dx-datagrid-search-text">$1</mark>');
      src_str = src_str.replace(/(<mark class="dx-datagrid-search-text">[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/, '$1</mark>$2<mark class="dx-datagrid-search-text">$4');
    }
    html.html(src_str);
  }

  isNullOrUndefined(obj: any) {
    if (_.isNull(obj) || _.isUndefined(obj))
      return true;
    return false;
  }

  downloadFile(blob: any, filename: string) {
    var url = window.URL.createObjectURL(blob);
    var linkElement = document.createElement('a');
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', filename);

    var clickEvent;
    var clickParams = {
      "view": window,
      "bubbles": true,
      "cancelable": false
    };
    clickEvent = new MouseEvent('click', clickParams);
    linkElement.dispatchEvent(clickEvent);
  }

  //Format epochTime(milliseconds) with angular(format:'short' is default, 'timezone':usertimezone is default)
  //date: timespan
  //format:https://docs.angularjs.org/api/ng/filter/date 'short' is default
  //timezone:'+HHMM' usertimezone is default
  formatDateFromEpoch(date: any, format: any, timezone: any) {
    if (_.isEmpty(format))
      format = 'short';
    if (_.isEmpty(timezone))
      timezone = moment(date).format('ZZ');

    return datepipe.transform(date, format, timezone);
  }

  convertSecondsToTime(seconds, withSeconds = false) {
    var res = "00:00";
    if (withSeconds)
      res += ":00";
    if (seconds === null) {
      res = "--:--";
      if (withSeconds)
        res += ":--";
    }
    else if (seconds > 0) {
      var hh = (seconds / 3600) >>> 0;
      var totsec = seconds - hh * 3600;
      var mm = (totsec / 60) >>> 0;
      totsec = totsec - mm * 60;

      res = this.formatNumberLength(hh, 2) + ":" + this.formatNumberLength(mm, 2);
      if (withSeconds)
        res += ":" + this.formatNumberLength(totsec, 2);
    }
    //console.log(res);

    return res;
  }

  formatNumberLength(num, length) {
    var r = "" + num;
    while (r.length < length) {
      r = "0" + r;
    }
    return r;
  }

  composeDriverName(driver) {
    var res = '';
    if (!_.isEmpty(driver)) {
      res = driver.lastName + ' ' + driver.firstName;
    }
    return res;
  }

  convertFromKmLiters(kmL) {
    var res: any = null;
    let identity = this.dataService.getCurrentUser();
    if (_.isNumber(kmL)) {
      switch (identity.user.preferences.consume.label) {
        case 'liter-100km':
          //# 6176 FZ DIVIDO SOLO SE LA DISTANZA è MAGGIORE DI 1
          if (kmL > 1)
            res = this.mathround(100 / kmL, 2);
          else
            res = this.mathround(kmL, 2);
          break;
        case 'mile-gallon_uk':
          res = this.mathround(kmL * MILES_CONV / GALLONS_CONV, 2);
          break;
        case 'mile-gallon_us':
          res = this.mathround(kmL * MILES_CONV / GALLONS_US_CONV, 2);
          break;
        default:
          res = this.mathround(kmL, 2);
          break;
      }
    }
    return res;
  }

  getPropertyValueFromString(obj, propName) {
    var res = obj;
    if (!_.isEmpty(obj)) {
      var props = propName.split('.');
      for (var i = 0; i < props.length; ++i) {
        if (!_.isEmpty(res))
          res = res[props[i]];
      }
    }
    return res;
  }

  getVolumeLabel() {
    let identity = this.dataService.getCurrentUser();
    switch (identity.user.preferences.volume.label) {
      case 'gallon_uk':
        return 'Gal';
      case 'gallon_us':
        return 'Gal(US)';
      default:
        return 'L';
    }
  }



  convertFromLiters(liters) {
    var res: any = null;
    let identity = this.dataService.getCurrentUser();
    if (_.isNumber(liters)) {
      switch (identity.user.preferences.volume.label) {
        case 'gallon_uk':
          res = this.mathround(liters * GALLONS_CONV, 2);
          break;
        case 'gallon_us':
          res = this.mathround(liters * GALLONS_US_CONV, 2);
          break;
        default:
          res = this.mathround(liters, 2);
          break;
      }
    }
    return res;
  }

  cloneJsObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  toTimestamp_fromDateJS(dateJS) {
    var offset = dateJS.getTimezoneOffset();
    var timestamp = moment(dateJS).subtract(offset, 'm').valueOf();
    return timestamp;
  }

  getConsLabel(paramIn?: any) {
    let identity = this.dataService.getCurrentUser();
    let switcValue;
    if (paramIn)
      switcValue = paramIn;
    else
      switcValue = identity.user.preferences.consume.label

    switch (switcValue) {
      case 'km-liter':
        return 'Km/L';
      case 'mile-gallon_uk':
        return 'Mpg';
      case 'mile-gallon_us':
        return 'Mpg(US)';
      default:
        return 'L/100Km';
    }
  }

  /*#region date time utility*/
  /* USED in TimeSpan Chooser Component */
  calcTimeperiod(fromDate: any, toDate: any) {
    //var from = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    //var to  = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    var from = moment(fromDate);
    var to = moment(toDate);
    var now = moment(new Date)
    if (to <= now)
      return {
        fromDate: from.startOf('day').valueOf(),
        toDate: to.valueOf()
      };
    else
      return {
        fromDate: from.startOf('day').valueOf(),
        toDate: to.endOf('day').valueOf()
      };
  }

  /*#region date time utility*/
  /* USED in TimeSpan Chooser Component */
  calcDateTimeperiod(fromDate: any, toDate: any, formatDateTimeIn: string) {
    //var from = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    //var to  = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    var from = moment(fromDate).startOf("day").format(formatDateTimeIn);
    var to = moment(toDate).endOf("day").format(formatDateTimeIn);

    return {
      fromDate: moment(from).valueOf(),
      toDate: moment(to).valueOf()
    };
  }

  calcDaily(fromDate: any) {
    //var from = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    //var to  = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    var from = moment(fromDate).startOf("day");
    var to = from.clone().endOf("day");




    return {
      fromDate: from.startOf('day').valueOf(),
      toDate: to.valueOf()
    };
  }

  calcDailyTime(fromDate: any) {
    //var from = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    //var to  = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    var from = moment(fromDate);
    var to = from.clone();

    return {
      fromDate: from.startOf('day').valueOf(),
      toDate: to.endOf('day').valueOf()
    };
  }



  calcWeek(fromDate: any) {
    //var from = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    //var to  = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    var from = moment(fromDate);
    var to = from.clone();
    from.startOf('week').add(1, 'days');
    to.endOf('week').add(1, 'days');

    return {
      fromDate: from.startOf('day').valueOf(),
      toDate: to.endOf('day').valueOf()
    };
  }

  calcLast7Days() {
    //var from = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    //var to = moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).utc();
    var from = moment();
    var to = from.clone();
    from.subtract(6, 'days');

    return {
      fromDate: from.startOf('day').valueOf(),
      toDate: to.endOf('day').valueOf()
    };
  }


  calcMonth(fromDate: any) {
    //var from = moment.tz(fromDate, 'UTC');
    //var to = from.clone();
    var from = moment(fromDate);
    var to = from.clone();

    from.startOf('month');
    to.endOf('month');

    return {
      fromDate: from.startOf('day').valueOf(),
      toDate: to.endOf('day').valueOf()
    };
  }

  convertFromKm(km) {
    var MILES_CONV = 0.621371192;
    let identity = this.dataService.getCurrentUser();
    var res: any = null;
    if (_.isNumber(km)) {
      if (identity.user.preferences.length.label === 'mile')
        res = this.mathround(km * MILES_CONV, 2);
      else
        res = this.mathround(km, 2);
    }
    return res;
  }

  getSpeedLabel() {
    let identity = this.dataService.getCurrentUser();
    switch (identity.user.preferences.length.label) {
      case 'mile':
        return 'Mph';
      default:
        return 'Km/h';
    }
  }

  toUserTimezoneDateJS(timestamp) {
    var userTimestamp = this.toUserTimezone(timestamp);
    var browserOffset = moment.tz(timestamp, moment.tz.guess()).utcOffset();
    var userDate = moment(userTimestamp).subtract(browserOffset, 'm').toDate();
    return userDate;
  }

  getDistanceLabel() {
    let identity = this.dataService.getCurrentUser();
    switch (identity.user.preferences.length.label) {
      case 'mile':
        return 'Mil';
      default:
        return 'Km';
    }
  }


  mathround(value, decimals) {
    return Number((value).toFixed(decimals));
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getHoliday(currentDate) {
    if (!_.isDate(currentDate))
      return { isHoliday: false, holiday: "" };
    /*capodanno*/
    if (currentDate.getDate() === 1 && currentDate.getMonth() + 1 === 1) {
      return { isHoliday: true, holiday: "Capodanno" };
    }

    /*6 gennaio epifania*/
    if (currentDate.getDate() === 6 && currentDate.getMonth() + 1 === 1) {
      return { isHoliday: true, holiday: "Epifania" };
    }

    /*Liberazione*/
    if (currentDate.getDate() === 25 && currentDate.getMonth() + 1 === 4) {
      return { isHoliday: true, holiday: "Liberazione" };
    }

    /*Lavoratori*/
    if (currentDate.getDate() === 1 && currentDate.getMonth() + 1 === 5) {
      return { isHoliday: true, holiday: "Lavoratori" };
    }

    /*Repubblica*/
    if (currentDate.getDate() === 2 && currentDate.getMonth() + 1 === 6) {
      return { isHoliday: true, holiday: "Repubblica" };
    }

    /*Ferragosto*/
    if (currentDate.getDate() === 15 && currentDate.getMonth() + 1 === 8) {
      return { isHoliday: true, holiday: "Ferragosto" };
    }


    /*Ognissanti*/
    if (currentDate.getDate() === 1 && currentDate.getMonth() + 1 === 11) {
      return { isHoliday: true, holiday: "Ognissanti" };
    }

    /*Immacolata*/
    if (currentDate.getDate() === 8 && currentDate.getMonth() + 1 === 12) {
      return { isHoliday: true, holiday: "Immacolata" };
    }

    /*Natale*/
    if (currentDate.getDate() === 25 && currentDate.getMonth() + 1 === 12) {
      return { isHoliday: true, holiday: "Natale" };
    }

    /*S.Stefano*/
    if (currentDate.getDate() === 26 && currentDate.getMonth() + 1 === 12) {
      return { isHoliday: true, holiday: "S.Stefano" };
    }
    //Pasqua
    var pasqua = this.easterSunday(currentDate.getFullYear());
    if (currentDate === pasqua) {
      return { isHoliday: true, holiday: "Pasqua" };
    }
    //Pasquetta
    // if (currentDate === pasqua.setDate(pasqua.getDate())) {
    //     return { isHoliday: true, holiday: "Pasquetta" };
    // }

    /* if (currentDate === pasqua.setDate(pasqua.getDate())) {
       return { isHoliday: true, holiday: "Pasquetta" };
   }*/
    //Sunday
    if (currentDate.getDay() === 0) {
      return { isHoliday: true, holiday: "" };
    }

    return { isHoliday: false, holiday: "" };
  }

  easterSunday(year) {
    var a = (year / 100 | 0) * 1483 - (year / 400 | 0) * 2225 + 2613;
    var b = ((year % 19 * 3510 + (a / 25 | 0) * 319) / 330 | 0) % 29;
    var c = 148 - b - ((year * 5 / 4 | 0) + a - b) % 7;
    return new Date(year, (c / 31 | 0), c % 31 + 1);
  }

  getDayFromTimestamp(timestamp) {
    return moment(timestamp).format('D');
  }

  extractDriversFromTeams(teams, sorted) {
    let drivers: any[] = [];
    _.each(teams, (team: any) => {
      _.each(team.items, (driver: any) => {
        if (_.isEmpty(_.find(drivers, (driverfilled: any) => {
          return driver.uid === driverfilled?.uid;
        }))
        ) {
          drivers.push(driver);
        }
      });
    });
    if (drivers.length > 0 && sorted) {
      drivers = _.sortBy(drivers, (driver) => {
        return [driver.lastName.toLowerCase(), driver.firstName.toLowerCase()].join("_");
      });
    }
    return drivers;
  }

  extendDriversProperty(drivers): void {
    _.each(drivers, (driver: any) => {
      driver.name = driver.lastName + ' ' + driver.firstName;
      driver.nameWithCardId = this.getDrivernameWithCardId(driver);
    });
  }

  downloadBlob(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  updateData(newData: any) {
    this.dataTable.next(newData);
  }


  async exportData(displayMessage: string, visuals: any, activePage: any, report: any, format?: any): Promise<void> {
    if (!report) {
      displayMessage = 'Report not available.';
      console.log(displayMessage);
      return;
    }
    if (!activePage) {
      displayMessage = 'No Active page found';
      return;
    }
    try {
      const visual = await activePage.getVisualByName(visuals);
      const data = await visual.exportData(models.ExportDataType.Summarized);

      if (format === 'csv') {
        this.downloadCSV(data.data);
      } else if (format === 'excel') {
        this.downloadExcel(data.data);
      }
    } catch (error) {
      console.log("error exporting data:", error);
    }
  }

  downloadCSV(csvData: string): void {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'report.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  downloadExcel(csvData: string): void {
    // Step 1: Convertire i dati CSV in un array di righe
    const rows = csvData.split('\n').filter(row => row.trim() !== '');

    // Step 2: Estrarre le intestazioni (la prima riga del CSV)
    const headers = rows[0].split(',');

    // Step 3: Estrarre i dati (le righe successive)
    const data = rows.slice(1).map(row => row.split(','));

    // Creare un array di oggetti per XLSX
    const jsonData = data.map(row => {
      const obj: { [key: string]: string } = {};
      headers.forEach((header, index) => {
        obj[header] = row[index] || ''; // Associa ogni valore alla sua intestazione
      });
      return obj;
    });

    // Step 4: Creare un worksheet da jsonData
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);

    // Step 5: Creare un workbook e aggiungere il worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Dati');

    // Step 6: Scrivere il file Excel
    XLSX.writeFile(wb, 'export.xlsx');
  }

}
